<template>
  <v-card class="mx-auto" :disabled="ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: page_route ? page_route + '.rha_catastrophics' : '',
                  params: { id: id },
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              $route.meta.title +
              (tbl.length > 0 ? ' (' + tbl.length + ')' : '')
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip
            v-if="
              tbl.length > 0 &&
              log.permissions.rha_benefit_details_checks.update
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="teal"
                dark
                @click.stop="dataDlg"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span v-text="'Editar'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="id" />
        </v-col>
        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'DETALLE'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="tbl_srch"
                    append-icon="mdi-magnify"
                    label="Buscar en tabla..."
                    single-line
                    hide-details
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :headers="tbl_hdrs"
                    :search="tbl_srch"
                    :items="tbl"
                    :loading="ldg"
                    :items-per-page="15"
                    dense
                  >
                    <template v-slot:item.key="{ item }">
                      <b v-text="item.key + 1" />
                    </template>
                    <template v-slot:item.select_1="{ item }">
                      <v-icon v-if="item.select_1" small color="success">
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else small> mdi-checkbox-blank-outline </v-icon>
                    </template>
                    <template v-slot:item.select_2="{ item }">
                      <v-icon v-if="item.select_2" small color="success">
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else small> mdi-checkbox-blank-outline </v-icon>
                    </template>
                    <template v-slot:item.select_3="{ item }">
                      <v-icon v-if="item.select_3" small color="success">
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else small> mdi-checkbox-blank-outline </v-icon>
                    </template>
                    <template v-slot:item.select_4="{ item }">
                      <v-icon v-if="item.select_4" small color="success">
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else small> mdi-checkbox-blank-outline </v-icon>
                    </template>
                    <template v-slot:item.select_5="{ item }">
                      <v-icon v-if="item.select_5" small color="success">
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else small> mdi-checkbox-blank-outline </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="data_dlg" scrollable persistent max-width="1800">
      <v-card tile :disabled="data_dlg_ldg" :loading="data_dlg_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> EDITAR </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="data_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="data">
          <v-row dense>
            <v-col cols="12" class="pt-3" />
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <v-form v-on:submit.prevent ref="data_form" lazy-validation>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th v-text="'#'" />
                            <th v-text="'Tipo'" />
                            <th v-text="'Código'" />
                            <th v-text="'Descripción'" />
                            <th v-text="'Sub-Tipo'" />
                            <th v-text="'Definición'" />
                            <th v-text="'Cantidad'" />
                            <th v-text="'Comentario'" />
                            <th v-text="'Propios del procedimiento'" />
                            <th v-text="'Sabana Qx'" />
                            <th v-text="'NotaQx'" />
                            <th v-text="'Etiqueta'" />
                            <th v-text="'Contacto MT'" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, i) in data"
                            :key="i"
                            v-if="item.active"
                          >
                            <td v-text="i + 1" />
                            <td>
                              <div
                                v-text="
                                  item.benefit_detail.benefit.benefit_type
                                    .benefit_type
                                "
                              />
                            </td>
                            <td>
                              <div v-text="item.benefit_detail.benefit.code" />
                            </td>
                            <td>
                              <div v-text="item.benefit_detail.description" />
                            </td>
                            <td>
                              <div
                                v-text="
                                  item.benefit_detail.benefit_type
                                    ? item.benefit_detail.benefit_type
                                        .benefit_type
                                    : null
                                "
                              />
                            </td>
                            <td>
                              <div v-text="item.benefit_detail.definition" />
                            </td>
                            <td>
                              <div v-text="item.quantity" />
                            </td>
                            <td>
                              <v-text-field
                                v-model="item.comment"
                                dense
                              />
                            </td>
                            <td>
                              <v-checkbox
                                v-model="item.select_1"
                                color="success"
                              />
                            </td>
                            <td>
                              <v-checkbox
                                v-model="item.select_2"
                                color="success"
                              />
                            </td>
                            <td>
                              <v-checkbox
                                v-model="item.select_3"
                                color="success"
                              />
                            </td>
                            <td>
                              <v-checkbox
                                v-model="item.select_4"
                                color="success"
                              />
                            </td>
                            <td>
                              <v-checkbox
                                v-model="item.select_5"
                                color="success"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-form>
                </v-col>

                <v-col cols="6"> </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn small color="success" @click.prevent="saveData">
                    Guardar
                    <v-icon right> mdi-send </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken, rules, msgConfirm, msgAlert } from "@/control";
import FaqDlg from "@/components/FaqDlg.vue";
import RhaData from "@/components/RhaData.vue";

export default {
  components: {
    FaqDlg,
    RhaData,
  },
  data() {
    return {
      rules: rules(),
      id: this.$route.params.id ? this.$route.params.id : 0,
      log: this.$store.getters.getLogin,
      page_route: null,
      ldg: false,
      tbl: [],
      tbl_hdrs: [
        {
          text: "#",
          value: "key",
          filterable: false,
          width: "50",
        },
        {
          text: "Tipo",
          value: "benefit_detail.benefit.benefit_type.benefit_type",
          filterable: true,
        },
        {
          text: "Código",
          value: "benefit_detail.benefit.code",
          filterable: true,
        },
        {
          text: "Descripción",
          value: "benefit_detail.description",
          filterable: true,
        },
        {
          text: "Sub-Tipo",
          value: "benefit_detail.benefit_type.benefit_type",
          filterable: true,
        },
        {
          text: "Definición",
          value: "benefit_detail.definition",
          filterable: true,
        },
        {
          text: "Cantidad",
          value: "quantity",
          filterable: true,
        },
        {
          text: "Comentario",
          value: "comment",
          filterable: true,
        },
        {
          text: "Propios del procedimiento",
          value: "select_1",
          filterable: false,
        },
        {
          text: "Sabana Qx",
          value: "select_2",
          filterable: false,
        },
        {
          text: "NotaQx",
          value: "select_3",
          filterable: false,
        },
        {
          text: "Etiqueta",
          value: "select_4",
          filterable: false,
        },
        {
          text: "Contacto MT",
          value: "select_5",
          filterable: false,
        },
      ],
      tbl_srch: "",
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      data: [],
      data_dlg: false,
      data_dlg_ldg: false,
    };
  },
  methods: {
    getTbl() {
      this.ldg = true;
      this.tbl = [];

      Axios.get(
        URL_API + "/rhas/" + this.id + "/benefit_details",
        headersToken(this.log.token)
      ).then((res) => {
        this.tbl = res.data.data;
        this.ldg = false;
      });
    },
    dataDlg() {
      this.data = JSON.parse(JSON.stringify(this.tbl));
      this.data_dlg_ldg = false;
      this.data_dlg = true;
    },
    saveData() {
      if (this.$refs.data_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar la información?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.data_dlg_ldg = true;

              Axios.post(
                URL_API + "/rhas/benefit_details",
                { items: this.data },
                headersToken(this.log.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.data_dlg = false;
                  this.getTbl();
                }

                this.data_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },

  mounted() {
    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.log.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    this.getTbl();
  },
};
</script>